import React from "react";
import { Card, Space, Typography } from "antd";

const styles: React.CSSProperties = {
  display: "flex",
  flexWrap: "wrap",
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "16px",
  paddingBottom: 16,
};

export const App = React.memo(() => {
  return (
    <Space
      direction="vertical"
      style={{ width: "100%", height: "100vh", alignItems: "center" }}
    >
      <Typography.Title style={{ fontFamily: "PressStart2P" }}>
        ПОРТФОЛИО
      </Typography.Title>
      <Space size={16} style={styles}>
        <Card
          title="Сервис ЭДО Вандок"
          extra={
            <a
              href="https://vandoc.vangog-it.ru"
              target="_blank"
              rel="noreferrer"
            >
              Перейти
            </a>
          }
          style={{ width: 350, height: 450 }}
        >
          <div>
            <p style={{ textIndent: "0" }}>
              Сервис для составления документов в формате xml, посредством
              заполнения формы-анкеты.
            </p>
            <h4 style={{ marginBottom: "5px" }}>Разработка:</h4>
            <p>2023</p>
            <h4 style={{ marginBottom: "5px" }}>Демо доступ:</h4>
            <p>login: admin@vandoc.ru</p>
            <p>password: admin</p>
            <h4 style={{ marginBottom: "5px" }}>Стек:</h4>
            <p>
              react, typescript, mobx, .net core webapi, c#, postgres, nodejs,
              express, docker, gitlab-ci, nginx
            </p>
          </div>
        </Card>
        <Card
          title="Сервис tnetun.am"
          extra={
            <a
              href="https://tnetun.vangog-it.ru"
              target="_blank"
              rel="noreferrer"
            >
              Перейти
            </a>
          }
          style={{ width: 350, height: 450 }}
        >
          <div>
            <p style={{ textIndent: "0" }}>
              Сервис для поиска и продажи вещей. Создания и публикации
              объявлений, поиска.
            </p>
            <h4 style={{ marginBottom: "5px" }}>Разработка:</h4>
            <p>2023</p>
            <h4 style={{ marginBottom: "5px" }}>Демо доступ:</h4>
            <p>login: admin</p>
            <p>password: admin</p>
            <h4 style={{ marginBottom: "5px" }}>Стек:</h4>
            <p>
              react, typescript, mobx, .net core webapi, c#, postgres, nodejs,
              express, docker, gitlab-ci, nginx
            </p>
          </div>
        </Card>
        <Card
          title="Сервис wikikot.ru"
          extra={
            <a href="https://dev.wikikot.ru" target="_blank" rel="noreferrer">
              Перейти
            </a>
          }
          style={{ width: 350, height: 450 }}
        >
          <div>
            <p style={{ textIndent: "0" }}>
              Сервис для поиска информации о породах кошек. Так же есть
              возможность публикации своих статей и отображения их в ленте
              новостей других пользователей.
            </p>
            <h4 style={{ marginBottom: "5px" }}>Разработка:</h4>
            <p>2023</p>
            <h4 style={{ marginBottom: "5px" }}>Демо доступ:</h4>
            <p>login: admin</p>
            <p>password: admin</p>
            <h4 style={{ marginBottom: "5px" }}>Стек:</h4>
            <p>
              react, typescript, mobx, .net core webapi, c#, postgres, docker,
              gitlab-ci, nginx
            </p>
          </div>
        </Card>
        <Card
          title="Приложение кофейни"
          extra={
            <a
              href="https://gokomne.vangog-it.ru/ordering"
              target="_blank"
              rel="noreferrer"
            >
              Перейти
            </a>
          }
          style={{ width: 350, height: 450 }}
        >
          <div>
            <p style={{ textIndent: "0" }}>
              Приложение для онлайн заказов кофейни{" "}
              <a
                href="https://yandex.ru/maps/org/go_ko_mne/144497082087/reviews/?ll=37.559280%2C55.787690&z=16"
                target="_blank"
                rel="noreferrer"
              >
                Go ко мне
              </a>
              .
            </p>
            <h4 style={{ marginBottom: "5px" }}>Разработка:</h4>
            <p>2023</p>
            <h4 style={{ marginBottom: "5px" }}>Стек:</h4>
            <p>
              react, typescript, mobx, postgres, nodejs, express, docker,
              gitlab-ci, nginx
            </p>
          </div>
        </Card>
        <Card
          title="Сервис lovgorod.ru"
          extra={
            <a href="https://lovgorod.ru" target="_blank" rel="noreferrer">
              Перейти
            </a>
          }
          style={{ width: 350, height: 450 }}
        >
          <div>
            <p style={{ textIndent: "0" }}>
              Сервис для поиска друзей, любовников, партнеров. Tinder-like
              сервис с карточками, свайпами и чатами.
            </p>
            <h4 style={{ marginBottom: "5px" }}>Разработка:</h4>
            <p>2022</p>
            <h4 style={{ marginBottom: "5px" }}>Демо доступ:</h4>
            <p>login: anton11111@gmail.com</p>
            <p>password: lovgorod93Ki</p>
            <h4 style={{ marginBottom: "5px" }}>Стек:</h4>
            <p>
              react, typescript, nodejs, express, postgres, docker, gitlab-ci,
              nginx
            </p>
          </div>
        </Card>
        <Card
          title="Игра Digger"
          extra={
            <a
              href="https://yandex.ru/games/app/221003?draft=true&lang=ru"
              target="_blank"
              rel="noreferrer"
            >
              Перейти
            </a>
          }
          style={{ width: 350, height: 328 }}
        >
          <div>
            <p style={{ textIndent: "0" }}>
              RPG игра-бродилка. На стадии прототипирования.
            </p>
            <h4 style={{ marginBottom: "5px" }}>Разработка:</h4>
            <p>2023</p>
            <h4 style={{ marginBottom: "5px" }}>Стек:</h4>
            <p>Godot Engine, GDScript</p>
          </div>
        </Card>
        <Card
          title="Агрегатор новостей"
          extra={
            <a
              href="https://t.me/all_news_agg"
              target="_blank"
              rel="noreferrer"
            >
              Перейти
            </a>
          }
          style={{ width: 350 }}
        >
          <div>
            <p style={{ textIndent: "0" }}>
              Telegram бот, агрегирующий новости с разных источников(телеграм
              каналов/сайтов), объединенные в одном месте для удобства.
            </p>
            <h4 style={{ marginBottom: "5px" }}>Разработка:</h4>
            <p>2023</p>
            <h4 style={{ marginBottom: "5px" }}>Стек:</h4>
            <p>python, docker</p>
          </div>
        </Card>
        <Card
          title="Бот any-english"
          extra={
            <a href="https://t.me/any_english" target="_blank" rel="noreferrer">
              Перейти
            </a>
          }
          style={{ width: 350, height: 328 }}
        >
          <div>
            <p style={{ textIndent: "0" }}>
              Telegram бот канала Any English для атоматического составления
              постов и отправки в канал.
            </p>
            <h4 style={{ marginBottom: "5px" }}>Разработка:</h4>
            <p>2023</p>
            <h4 style={{ marginBottom: "5px" }}>Стек:</h4>
            <p>nodejs, docker</p>
          </div>
        </Card>
      </Space>
    </Space>
  );
});
