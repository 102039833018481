import { BrowserRouter as RouterBase, Switch, Route } from "react-router-dom";
import { App } from "./application";
// import { Chat } from "./chat";

export const Router = () => {
  return (
    <RouterBase>
      <Switch>
        {/* <Route path="/chat">
          <Chat />
        </Route>
        <Route path="*">
          404
        </Route> */}
        <Route path="/">
          <App />
        </Route>
      </Switch>
    </RouterBase>
  );
};
